// 20240930：主页上己方的 CSS 和 JS 的打包和注入
// 注：通过该配置文件管理了页面上需要的资源，所以页面上的 <link href> <script href> 标签需要删除掉
// 主页上第三方的 CSS 和 JS 通过 HtmlWebpackTagsPlugin 插件管理
// // 导入样式表
// // 这里的 @css 实际指向 dist/public/css
// import "@css/global.css";
// import "@css/commonHead.css";
// import "@css/index.css";
// import "@css/commonFooter.css";
// // 导入公共库或脚本：所有页面通用它部分，单独的入口文件管理
// // 这两个是第三方静态资源，每个页面是都要有
// import '@js/ejs.min.js';
// // '/js/axios/dist/axios.min.js';
// import '@js/logToConsoleAndServer.js';
// import '@js/commonHead.js';
// import '@js/commonFooter.js';
// import '@js/common/clickFlame.js';

// 20241002: 将 webpack.config.js 和 wpEntry 目录向后移入到 dist 目录中了，直接使用相对路径！
//           这里出现的资源都将被合并打包 成 bundle 包
// 相对于本文件的相对路径！
// CSS:
// 1、公共部分
// 所有页面的公共部分，由单独的入口文件管理了
// import "../public/css/global.css";
// import "../public/css/commonHead.css";
// import "../public/css/messageDialogBox.css";
// import "../public/css/commonFooter.css";
// 2、独有部分
import "../public/css/index.css";

// JS:
// 1、公共部分
// 通过静态资源的方式，导入第三方库
// 这两个是第三方静态资源，通用性，每个页面都要有。—— 做为静态资源注入页面进行管理，不打包！
// import '../public/js/ejs.min.js';
// '/js/axios/dist/axios.min.js';
// 所有页面的公共部分，由单独的入口文件管理了
// import '../public/js/logToConsoleAndServer.js';
// import '../public/js/commonHead.js';
// import '../public/js/messageDialogBox.js'
// 开发阶段不引用该脚本，正式上线时测试后引入
// import '../public/js/commonFooter.js';
// 主页面 <可选> -- 虽然是可选，但如果其它入口文件是有超过规定数量的引入，它其实还是会被打包进去的。
// import '../public/js/common/clickFlame.js';

// 2、独有部分
// 通过打包的方式管理己方页面上的库
// 该页面的私有部分，但代码量小，直接写入了ejs 页面中的 <script> 标签内了。后期代码变大再单独管理
import '../public/js/socialInteraction.js';
